'use client'

import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Image,
    Button,
    useColorMode,
} from '@chakra-ui/react'
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiFileText,
    FiDollarSign,
    FiArchive,
    FiPackage,
    FiUser,
    FiBriefcase,
    FiSend,
    FiInbox,
    FiBarChart,
    FiBook,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import ConsultaFluxoCaixa from '../pages/fluxocaixa/ConsultaFluxoCaixa'
import { BrowserRouter, Route, Routes, NavLink } from 'react-router-dom'
import { FRONT_URL } from '../globals/globals'
import ConsultaOrcamento from '../pages/orcamento/ConsultaOrcamento'
import ConsultaItem from '../pages/item/ConsultaItem'
import ConsultaFluxoCaixaCategoria from '../pages/fluxocaixacategoria/ConsultaFluxoCaixaCategoria'
import ConsultaFuncionario from '../pages/funcionario/ConsultaFuncionario'
import ConsultaEmpresa from '../pages/empresa/ConsultaEmpresa'
import ConsultaDestinatario from '../pages/destinatario/ConsultaDestinatario'
import ConsultaEstoque from '../pages/estoque/ConsultaEstoque'
import Dashboard from '../pages/dashboard/Dashboard'
import {ContractPDF} from '../components/pdfFechamento/pdfFechamento'
import ConsultaFechamento from '../pages/fechamento/ConsultaFechamento'



interface LinkItemProps {
    name: string
    icon: IconType
    redir: string
}

interface NavItemProps extends FlexProps {
    icon: IconType
    children: React.ReactNode
    redir: string
}

interface MobileProps extends FlexProps {
    onOpen: () => void
}

interface SidebarProps extends BoxProps {
    onClose: () => void
}

function logout() {

    window.location.href = '/';

}

const LinkItems: Array<LinkItemProps> = [
    { name: 'Fluxo de Caixa', icon: FiDollarSign, redir: 'consultar/fluxo-caixa' },
    { name: 'Orçamentos', icon: FiFileText, redir: 'consultar/orcamentos' },
    { name: 'Fechamentos', icon: FiBook, redir: 'consultar/fechamentos' },
    { name: 'Estoque', icon: FiPackage, redir: 'consultar/estoque' },
    { name: 'Itens', icon: FiArchive, redir: 'consultar/itens' },
    { name: 'Pessoas', icon: FiUser, redir: 'consultar/pessoas' },
    { name: 'Empresas', icon: FiBriefcase, redir: 'consultar/empresas' },
    { name: 'Destinatários', icon: FiSend, redir: 'consultar/destinatarios' },
    { name: 'Categorias', icon: FiInbox, redir: 'consultar/categorias' },
    { name: 'Dashboard', icon: FiBarChart, redir: 'consultar/dashboard' }
]

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" width={'100%'} justifyContent="space-between" backgroundColor={'black'}>
                <Image src="https://i.pinimg.com/736x/0e/35/20/0e3520508f83b89f7cf31ec123539127.jpg" fit="contain" height={'20'} width={'100%'} />
                <CloseButton display={{ base: 'flex', md: 'none' }} color={'white'} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} redir={link.redir} width={'100%'}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    )
}

const NavItem = ({ icon, children, redir, ...rest }: NavItemProps) => {

    return (
        <Box
            onClick={() => {
                window.location.href = `${FRONT_URL}${redir}`
            }}
            as="a"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                paddingLeft={'8'}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'gray.700',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                hideBelow="md"
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">

                Logo
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        {/* <Button onClick={toggleColorMode}>
                            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                        </Button> */}
                        <Button
                            marginLeft={'15px'}
                            as={'a'}
                            display={'inline-flex'}
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg={'pink.400'}
                            href={'#'}
                            onClick={() => logout()}
                            _hover={{
                                bg: 'pink.300',
                            }}>
                            Sair
                        </Button>
                    </Menu>
                </Flex>
            </HStack >
        </Flex >
    )
}

const SidebarWithHeader = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box minH="100vh" bg={useColorModeValue('white.100', 'gray.900')}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 1, md: 60 }} p="4">
                <BrowserRouter>
                    <Routes>
                        <Route path="/consultar/fluxo-caixa" element={<ConsultaFluxoCaixa />} />
                        <Route path="/consultar/orcamentos" element={<ConsultaOrcamento />} />
                        <Route path="/consultar/itens" element={<ConsultaItem />} />
                        <Route path="/consultar/categorias" element={<ConsultaFluxoCaixaCategoria />} />
                        <Route path="/consultar/pessoas" element={<ConsultaFuncionario />} />
                        <Route path="/consultar/empresas" element={<ConsultaEmpresa />} />
                        <Route path="/consultar/destinatarios" element={<ConsultaDestinatario />} />
                        <Route path="/consultar/estoque" element={<ConsultaEstoque />} />
                        <Route path="/fechamento/pdf/:id" element={<ContractPDF/>} />
                        <Route path="/consultar/dashboard" element={<Dashboard />} />
                        <Route path="/consultar/fechamentos" element={<ConsultaFechamento />} />
                    </Routes>
                </BrowserRouter>
            </Box>
        </Box>
    )
}

export default SidebarWithHeader