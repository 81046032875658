import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    FormControl,
    FormErrorMessage,
    AccordionItem,
    Accordion,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { useForm } from 'react-hook-form';
import { GetDestinatariosGeral, GetEmpresas, GetEndereco, GetFluxoCaixaCategoria, GetFuncionarios, GetItens, GetTipoDestinatario, handleCEP, handleCPF, handlePhone, mascaraMoeda, verifyCPF } from '../../services/servicesgeneral';
import Empresa from '../../types/empresa';
import Funcionario from '../../types/funcionario';
import DestinatarioGeral from '../../types/destinatariogeral';
import TipoDestinatario from '../../types/tipodestinatario';
import FluxoCaixaCategoria from '../../types/fluxocaixacategoria';
import axios, { AxiosError } from 'axios';
import Item from '../../types/item';

type Props = {
    createdItem: any
}

export default function FuncionarioNew({ createdItem }: Props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies()
    const toast = useToast()

    const {
        handleSubmit,
        register,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm()

    function onSubmit(values: any) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'authorization': cookies['authorization']
            }
        }

        axios['post'](`${API_URL}pessoas`, values, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Pessoa Física criada com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                createdItem(resp.data)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao criar pessoa física"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'} justifyContent={'center'}>
                <AddIcon />
                <Text marginLeft={'10px'}>
                    Inserir
                </Text>
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)} id='createFluxoCaixaForm'>
                        <ModalHeader>Nova Pessoa</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Accordion defaultIndex={[0]} allowMultiple>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Dados
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <FormControl id="nome" isInvalid={Boolean(errors?.nome)}>
                                            <FormLabel marginTop={'15px'}>Nome</FormLabel>
                                            <Input type="text"
                                                {...register('nome', {
                                                    required: 'Este campo é obrigatório',
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.nome?.toString() && errors.nome.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="cpf" isInvalid={Boolean(errors?.cpf)}>
                                            <FormLabel marginTop={'15px'}>CPF</FormLabel>
                                            <Input type="text" maxLength={14}
                                                // eslint-disable-next-line no-restricted-globals
                                                onKeyUp={() => {

                                                    // eslint-disable-next-line no-restricted-globals
                                                    handleCPF(event)

                                                    let value = getValues('cpf')
                                                    if (value.length == 14) {

                                                        if (!verifyCPF(value)) {
                                                            setError('cpf', { type: "custom", message: "CPF Inválido" })
                                                        } else {
                                                            clearErrors('cpf')
                                                        }

                                                    }

                                                }}
                                                {...register('cpf', {
                                                    required: 'Este campo é obrigatório',
                                                    minLength: { value: 14, message: 'CPF Inválido' },
                                                    maxLength: { value: 14, message: 'CPF Inválido' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.cpf?.toString() && errors.cpf.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="telefone" isInvalid={Boolean(errors?.telefone)}>
                                            <FormLabel marginTop={'15px'}>Telefone</FormLabel>
                                            <Input type="text" maxLength={15}
                                                // eslint-disable-next-line no-restricted-globals
                                                onKeyUp={() => handlePhone(event)}
                                                {...register('telefone', {
                                                    required: 'Este campo é obrigatório',
                                                    minLength: { value: 14, message: 'Mínimo de 10 números' },
                                                    maxLength: { value: 15, message: 'Mínimo de 10 números' }
                                                })} />
                                            <FormErrorMessage>
                                                {errors.telefone?.toString() && errors.telefone.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="email" isInvalid={Boolean(errors?.email)}>
                                            <FormLabel marginTop={'15px'}>Email</FormLabel>
                                            <Input type="email"
                                                {...register('email', {
                                                    required: 'Este campo é obrigatório',
                                                })} />
                                            <FormErrorMessage>
                                                {errors.email?.toString() && errors.email.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Endereço
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <FormControl id="cep" isInvalid={Boolean(errors?.cep)}>
                                            <FormLabel marginTop={'15px'}>CEP</FormLabel>
                                            <Input type="text" maxLength={9}
                                                onKeyUp={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    handleCEP(event)
                                                    let value = getValues('cep')
                                                    if (value.length == 9) {
                                                        GetEndereco(value).then((data: any) => {
                                                            if (data) {

                                                                setValue('logradouro', data.logradouro)
                                                                setValue('bairro', data.bairro)
                                                                setValue('cidade', data.localidade)
                                                                setValue('estado', data.estado)

                                                            } else {

                                                                toast({
                                                                    title: 'Erro',
                                                                    description: "CEP não encontrado",
                                                                    status: 'error',
                                                                    isClosable: true,
                                                                    position: 'top',
                                                                })

                                                            }

                                                        });
                                                    }
                                                }}
                                                {...register('cep', {
                                                    minLength: { value: 9, message: 'CEP Inválido' },
                                                    maxLength: { value: 9, message: 'CEP Inválido' }
                                                })} />
                                            <FormErrorMessage>
                                                {errors.cep?.toString() && errors.cep.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="logradouro" isInvalid={Boolean(errors?.logradouro)}>
                                            <FormLabel marginTop={'15px'}>Logradouro</FormLabel>
                                            <Input type="text"
                                                {...register('logradouro', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.logradouro?.toString() && errors.logradouro.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="numero" isInvalid={Boolean(errors?.numero)}>
                                            <FormLabel marginTop={'15px'}>Número</FormLabel>
                                            <Input type="text"
                                                {...register('numero', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.numero?.toString() && errors.numero.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="bairro" isInvalid={Boolean(errors?.bairro)}>
                                            <FormLabel marginTop={'15px'}>Bairro</FormLabel>
                                            <Input type="text"
                                                {...register('bairro', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.bairro?.toString() && errors.bairro.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="complemento" isInvalid={Boolean(errors?.complemento)}>
                                            <FormLabel marginTop={'15px'}>Complemento</FormLabel>
                                            <Input type="text"
                                                {...register('complemento', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.complemento?.toString() && errors.complemento.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="cidade" isInvalid={Boolean(errors?.cidade)}>
                                            <FormLabel marginTop={'15px'}>Cidade</FormLabel>
                                            <Input type="text"
                                                {...register('cidade', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.cidade?.toString() && errors.cidade.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="estado" isInvalid={Boolean(errors?.estado)}>
                                            <FormLabel marginTop={'15px'}>Estado</FormLabel>
                                            <Input type="text"
                                                {...register('estado', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.estado?.toString() && errors.estado.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Dados Bancários
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <FormControl id="banco" isInvalid={Boolean(errors?.banco)}>
                                            <FormLabel marginTop={'15px'}>Banco</FormLabel>
                                            <Input type="text"
                                                {...register('banco', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.banco?.toString() && errors.banco.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="agencia" isInvalid={Boolean(errors?.agencia)}>
                                            <FormLabel marginTop={'15px'}>Agência</FormLabel>
                                            <Input type="text"
                                                {...register('agencia', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.agencia?.toString() && errors.agencia.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl id="conta_corrente" isInvalid={Boolean(errors?.conta_corrente)}>
                                            <FormLabel marginTop={'15px'}>Conta Corrente</FormLabel>
                                            <Input type="text"
                                                {...register('conta_corrente', {
                                                    minLength: { value: 2, message: 'Mínimo de 2 caracteres' },
                                                })} />
                                            <FormErrorMessage>
                                                {errors.conta_corrente?.toString() && errors.conta_corrente.message?.toString()}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </ModalBody>
                        <ModalFooter marginTop={'20px'}>
                            <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <Button type='submit'>Inserir</Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )

}


