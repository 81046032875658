import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    FormControl,
    FormErrorMessage,
    Box,
    Heading,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { GetDestinatariosGeral, GetEmpresas, GetFluxoCaixaCategoria, GetFuncionarios, GetItens, GetTipoDestinatario } from '../../services/servicesgeneral';
import Empresa from '../../types/empresa';
import Funcionario from '../../types/funcionario';
import DestinatarioGeral from '../../types/destinatariogeral';
import TipoDestinatario from '../../types/tipodestinatario';
import FluxoCaixaCategoria from '../../types/fluxocaixacategoria';
import axios, { AxiosError } from 'axios';
import Item from '../../types/item';
import { FaTrash } from 'react-icons/fa';

type Props = {
    createdFluxo: any
}

export default function FluxoCaixaNew({ createdFluxo }: Props) {

    const [empresas, setEmpresas] = useState<Empresa[] | null>(null)
    const [funcionarios, setFuncionarios] = useState<Funcionario[] | null>(null)
    const [destinatariosGerais, setdestinatariosGerais] = useState<DestinatarioGeral[] | null>(null)
    const [tipoDestinatario, setTipoDestinatario] = useState<TipoDestinatario[] | null>(null)
    const [fluxoCaixaCategoria, setfluxoCaixaCategoria] = useState<FluxoCaixaCategoria[] | null>(null)
    const [itens, setItens] = useState<Item[] | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies()
    const [destinatarios, setDestinatarios] = useState<any[] | null>(null)
    const toast = useToast()

    const {
        handleSubmit,
        register,
        reset,
        control,
        trigger,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "itens"
    });

    useEffect(() => {

        GetEmpresas(cookies['authorization']).then((data: Empresa[] | any) => {
            setEmpresas(data);
        });

        GetFuncionarios(cookies['authorization']).then((data: Funcionario[] | any) => {
            setFuncionarios(data);
        });

        GetDestinatariosGeral(cookies['authorization']).then((data: DestinatarioGeral[] | any) => {
            setdestinatariosGerais(data);
        });

        GetTipoDestinatario(cookies['authorization']).then((data: TipoDestinatario[] | any) => {
            setTipoDestinatario(data);
        });

        GetFluxoCaixaCategoria(cookies['authorization']).then((data: FluxoCaixaCategoria[] | any) => {
            setfluxoCaixaCategoria(data);
        });

        GetItens(cookies['authorization']).then((data: FluxoCaixaCategoria[] | any) => {
            setItens(data);
        });

    }, [])

    function onSubmit(values: any) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'authorization': cookies['authorization']
            }
        }

        if (values.itens.length > 0) {

            values.itens.forEach((itens: any) => {

                itens.itens_id = parseInt(itens.itens_id)

            })

        }

        values['valor'] = parseFloat(values['valor'])
        values['destinatario_id'] = parseInt(values['destinatario_id'])
        values['fluxo_caixa_categoria_id'] = parseInt(values['fluxo_caixa_categoria_id'])
        values['tipo_destinatario_id'] = parseInt(values['tipo_destinatario_id'])
        values['tipo_fluxo_caixa_id'] = parseInt(values['tipo_fluxo_caixa_id'])

        axios['post'](`${API_URL}fluxo-caixa`, values, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Fluxo de caixa criado com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                createdFluxo(resp.data)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao criar fluxo de caixa"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    function tipoDestinatarioIdOnChange(tipoDestinatarioId: number) {

        if (tipoDestinatarioId == 1) setDestinatarios(destinatariosGerais)

        else if (tipoDestinatarioId == 2 || tipoDestinatarioId == 3 || tipoDestinatarioId == 5) setDestinatarios(funcionarios)

        else if (tipoDestinatarioId == 4 || tipoDestinatarioId == 6) setDestinatarios(empresas)

    }

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'} justifyContent={'center'}>
                <AddIcon />
                <Text marginLeft={'10px'}>
                    Inserir
                </Text>
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)} id='createFluxoCaixaForm'>
                        <ModalHeader>Novo fluxo de caixa</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl id="descricao" isInvalid={Boolean(errors?.descricao)}>
                                <FormLabel marginTop={'15px'}>Descrição</FormLabel>
                                <Input type="text"
                                    {...register('descricao', {
                                        required: 'Este campo é obrigatório',
                                        minLength: { value: 4, message: 'Mínimo de 4 caracteres' },
                                    })} />
                                <FormErrorMessage>
                                    {errors.descricao?.toString() && errors.descricao.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="valor" isInvalid={Boolean(errors?.valor)}>
                                <FormLabel marginTop={'15px'}>Valor</FormLabel>
                                <Input type="number" step="0.01"
                                    {...register('valor', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.valor?.toString() && errors.valor.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="tipo_fluxo_caixa_id" isInvalid={Boolean(errors?.tipo_fluxo_caixa_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Tipo de Fluxo</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('tipo_fluxo_caixa_id', {
                                    required: 'Este campo é obrigatório',
                                })}>
                                <option value='1'>Entrada</option>
                                <option value='2'>Saída</option>
                            </Select>
                            <FormErrorMessage>
                                {errors.tipo_fluxo_caixa_id?.toString() && errors.tipo_fluxo_caixa_id.message?.toString()}
                            </FormErrorMessage>
                            <FormControl id="fluxo_caixa_categoria_id" isInvalid={Boolean(errors?.fluxo_caixa_categoria_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Categoria</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('fluxo_caixa_categoria_id', {
                                    required: 'Este campo é obrigatório',
                                })}>
                                {fluxoCaixaCategoria ? fluxoCaixaCategoria.length > 0 ? (fluxoCaixaCategoria?.map((categoria: FluxoCaixaCategoria) => (
                                    <option value={categoria.id}>{categoria.name}</option>
                                ))) : (<option disabled>Não há categorias cadastradas</option>) : (<option disabled>Não há categorias cadastradas</option>)}
                            </Select>
                            <FormErrorMessage>
                                {errors.fluxo_caixa_categoria_id?.toString() && errors.fluxo_caixa_categoria_id.message?.toString()}
                            </FormErrorMessage>
                            <FormControl id="tipo_destinatario_id" isInvalid={Boolean(errors?.tipo_destinatario_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Tipo de Destinatário</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('tipo_destinatario_id', {
                                    required: 'Este campo é obrigatório',
                                    onChange(event) {
                                        tipoDestinatarioIdOnChange(getValues('tipo_destinatario_id'))
                                    },
                                })}>
                                {tipoDestinatario ? tipoDestinatario.length > 0 ? (tipoDestinatario?.map((tipodestinatario: TipoDestinatario) => (
                                    <option value={tipodestinatario.id}>{tipodestinatario.nome}</option>
                                ))) : (<option disabled>Não há tipos de destinatário cadastrados</option>) : (<option disabled>Não há tipos de destinatário cadastrados</option>)}
                            </Select>
                            <FormErrorMessage>
                                {errors.tipo_destinatario_id?.toString() && errors.tipo_destinatario_id.message?.toString()}
                            </FormErrorMessage>
                            <FormControl id="destinatario_id" isInvalid={Boolean(errors?.destinatario_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Destinatário</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('destinatario_id', {
                                    required: 'Este campo é obrigatório',
                                }
                                )}>
                                {destinatarios ? destinatarios.length > 0 ? (destinatarios?.map((destinatario: any) => (
                                    <option value={destinatario.id}>{destinatario.nome}</option>
                                ))) : (<option disabled>Não há destinatários para esse tipo</option>) : (<option disabled>Não há destinatários para esse tipo</option>)}
                            </Select>
                            <FormErrorMessage>
                                {errors.destinatario_id?.toString() && errors.destinatario_id.message?.toString()}
                            </FormErrorMessage>
                            <Box marginTop={'30px'} flexDirection={'column'}>
                            <Heading size={'md'} fontWeight="medium" marginBottom={'15px'}>Itens</Heading>
                                <ul>
                                    {fields.map((item, index) => (
                                        <li key={item.id}>
                                            <FormControl id="itens_id" isInvalid={Boolean(errors?.itens_id)}></FormControl>
                                            <FormLabel marginTop={'15px'}>{`Item ${index + 1}`}</FormLabel>
                                            <Select placeholder='Selecione...'
                                                {...register(`itens.${index}.itens_id`, {
                                                    required: 'Este campo é obrigatório',
                                                })}>
                                                {itens ? itens.length > 0 ? (itens?.map((itens: Item) => (
                                                    <option value={itens.id}>{itens.nome}</option>
                                                ))) : (<option disabled>Não há itens cadastrados</option>) : (<option disabled>Não há itens cadastrados</option>)}
                                            </Select>
                                            <FormErrorMessage>
                                                {errors.itens_id?.toString() && errors.itens_id.message?.toString()}
                                            </FormErrorMessage>
                                            <FormControl id="quantidade" isInvalid={Boolean(errors?.quantidade)}>
                                                <FormLabel marginTop={'15px'}>{`Quantidade Item ${index + 1}`}</FormLabel>
                                                <Input type="text"
                                                    {...register(`itens.${index}.quantidade`, {
                                                        required: 'Este campo é obrigatório',
                                                    })} />
                                                <FormErrorMessage>
                                                    {errors.quantidade?.toString() && errors.quantidade.message?.toString()}
                                                </FormErrorMessage>
                                            </FormControl>
                                            <Button onClick={() => remove(index)} marginTop={'10px'}>
                                                <FaTrash />
                                                {/* <Text marginLeft={'5px'}>{`Excluir Item ${index+1}`}</Text> */}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                                <Button
                                    marginTop={'15px'}
                                    onClick={() => append({ quantidade: "", itens_id: "" })}
                                >
                                    <AddIcon /> <Text marginLeft={'5px'}>Adicionar item</Text>
                                </Button>
                            </Box>
                        </ModalBody>
                        <ModalFooter marginTop={'20px'}>
                            <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <Button type='submit'>Inserir</Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )

}


