import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ItemEdit from './empresaEdit';
import Funcionario from '../../types/funcionario';
import Empresa from '../../types/empresa';

type Props = {
    data: Empresa[],
    handleTable: any,
    tableReference: React.MutableRefObject<null>
}

export default function EmpresaTable({ data, handleTable, tableReference }: Props) {

    const [isLoading, setLoading] = useState(false)
    const { colorMode } = useColorMode()

    const handleEdit = (id: number): void => {

        var newData: Empresa[] = data?.filter(fluxo => (fluxo.id !== id));

        handleTable(newData);
    };

    const handleUpdate = (item: Empresa): void => {

        var newData: Empresa[] = data?.filter(fluxo => (fluxo.id !== item.id));

        newData.unshift(item)

        newData.sort(function (a, b) {
            return a.nome.localeCompare(b.nome.toString());
        });

        handleTable(newData);
    };

    function renderRows() {

        return data?.map((item: Empresa) => (
            <Tr key={item.id}>
                <Td whiteSpace={'pre-line'}>{item.nome}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'md'}>{item.cnpj}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'lg'}>{item.inscricao_estadual}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'lg'}>{item.contato}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'lg'}>{item.telefone}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'xl'}>{item.email}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'xl'}>{`${item.logradouro}, ${item.numero} (${item.complemento}) - ${item.bairro}, 
                ${item.cidade} - ${item.estado}, ${item.cep}`}</Td>
                <Td whiteSpace={'pre-line'}>
                    <ItemEdit item={item} handleEdit={handleEdit} handleUpdate={handleUpdate} />
                </Td>
            </Tr>
        ))

    }

    return (
        <Flex width={'100%'}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <TableContainer overflowX={'auto'} width={'100%'} alignItems={'center'}>
                    <Table variant='simple' colorScheme='gray' size='sm' table-layout='fixed' id='lista' ref={tableReference}>
                        <Thead>
                            <Tr>
                                <Th >Nome</Th>
                                <Th hideBelow={'md'}>CNPJ</Th>
                                <Th hideBelow={'lg'}>Inscrição Estadual</Th>
                                <Th hideBelow={'lg'}>Contato</Th>
                                <Th hideBelow={'lg'}>Telefone</Th>
                                <Th hideBelow={'xl'}>Email</Th>
                                <Th hideBelow={'xl'}>Endereço</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.length > 0 ? renderRows() : (<p>Não há dados...</p>)}
                        </Tbody>

                        <Tfoot>
                            <Tr>
                                <Th >Nome</Th>
                                <Th hideBelow={'md'}>CNPJ</Th>
                                <Th hideBelow={'lg'}>Inscrição Estadual</Th>
                                <Th hideBelow={'lg'}>Contato</Th>
                                <Th hideBelow={'lg'}>Telefone</Th>
                                <Th hideBelow={'xl'}>Email</Th>
                                <Th hideBelow={'xl'}>Endereço</Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Flex>
    )

}


