import fetchJsonp from "fetch-jsonp";
import { API_URL, FRONT_URL } from "../globals/globals";
import DestinatarioGeral from "../types/destinatariogeral";
import Empresa from "../types/empresa";
import FluxoCaixaCategoria from "../types/fluxocaixacategoria";
import Funcionario from "../types/funcionario";
import Item from "../types/item";
import ModalidadeFechamento from "../types/modalidadefechamento";

interface TipoDestinatario {
  id: number,
  tipo: string,
}



export const verifyCNPJ = (cnpj: string) => {

  cnpj = cnpj.replaceAll('.', '')
  cnpj = cnpj.replace('-', '')

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14)
    return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999")
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  var i
  for (i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
      pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != parseInt(digitos.charAt(0)))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != parseInt(digitos.charAt(1)))
    return false;

  return true;
}

export const verifyCPF = (strCPF: string) => {

  strCPF = strCPF.replaceAll('.', '')
  strCPF = strCPF.replace('-', '')

  var Soma;
  var Resto;
  var i;
  Soma = 0;
  if ([
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ].indexOf(strCPF) !== -1)
    return false

  for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const FormatData = (data: Date) => {

  const dataFormatted = data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear() + ' ' +
    data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()

  return dataFormatted;
}

export const FormatDataSemHora = (data: Date) => {

  const dataFormatted = data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear()

  return dataFormatted;
}

export const GetEndereco = async (cep: string): Promise<any> => {

  const endereco = await fetch(`https://viacep.com.br/ws/${cep.replace("-", "")}/json/`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => data);
  return endereco;
}

export const GetEmpresas = async (authorization: string): Promise<Empresa[]> => {

  const empresas = await fetch(`${API_URL}empresas`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return empresas;
}

export const GetEmpresaReceita = async (cnpj: string): Promise<any> => {

  cnpj = cnpj.replaceAll("-", "")
  cnpj = cnpj.replaceAll(".", "")
  cnpj = cnpj.replaceAll("/", "")

  const url = `https://receitaws.com.br/v1/cnpj/${cnpj}`;

  try {
    const response = await fetchJsonp(url);
    const data = await response.json();
    return data
  } catch (error) {
    console.error(error);
  }
}

export const GetModalidadeFechamento = async (authorization: string): Promise<ModalidadeFechamento[]> => {

  const empresas = await fetch(`${API_URL}modalidadefechamento`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
    console.log(empresas)
  return empresas;
}

export const GetFuncionarios = async (authorization: string): Promise<Funcionario[]> => {

  const funcionarios = await fetch(`${API_URL}pessoas`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return funcionarios;
}

export const GetDestinatariosGeral = async (authorization: string): Promise<DestinatarioGeral[]> => {

  const destinatarios = await fetch(`${API_URL}destinatarios`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return destinatarios;
}

export const GetTipoDestinatario = async (authorization: string): Promise<TipoDestinatario[]> => {

  const tipoDestinatario = await fetch(`${API_URL}tipo-destinatario`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return tipoDestinatario;
}

export const GetFluxoCaixaCategoria = async (authorization: string): Promise<FluxoCaixaCategoria[]> => {

  const fluxoCaixaCategoria = await fetch(`${API_URL}fluxo-categoria`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return fluxoCaixaCategoria;
}

export const GetItens = async (authorization: string): Promise<Item[]> => {

  const itens = await fetch(`${API_URL}itens`, {
    method: "GET",
    headers: {
      authorization: authorization
    }
  })
    .then((response) => response.json())
    .then((data) => data);
  return itens;
}

export const handleCNPJ = (event: any) => {
  let input = event.target
  input.value = cnpjMask(input.value)
}

export const cnpjMask = function cpf(v: any) {
  if (!v) return ""
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{2})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos                                    //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d)/, "$1/$2")       //Coloca um hífen entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2")       //Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}


export const handleCPF = (event: any) => {
  let input = event.target
  input.value = cpfMask(input.value)
}

export const cpfMask = function cpf(v: any) {
  if (!v) return ""
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos                                    //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}

export const handlePhone = (event: any) => {
  let input = event.target
  input.value = phoneMask(input.value)
}

export const phoneMask = (value: any) => {
  if (!value) return ""

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d)(\d{4})$/, "$1-$2")

  return value
}

export const handleInscricaoEstadual = (event: any) => {
  let input = event.target
  input.value = inscricaoEstadualMask(input.value)
}

export const inscricaoEstadualMask = (v: any) => {
  if (!v) return ""
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos                                    //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d)/, "$1/$2")       //Coloca um hífen entre o terceiro e o quarto dígitos
  // v=v.replace(/(\d{4})(\d)/,"$1-$2")       //Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}

export const handleCEP = (event: any) => {
  let input = event.target
  input.value = cepMask(input.value)
}

export const cepMask = (v: any) => {
  if (!v) return ""
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{5})(\d)/, "$1-$2")       //Coloca um ponto entre o terceiro e o quarto dígitos

  return v
}

export const mascaraMoeda = (event: any) => {
  const onlyDigits = event.target.value
    .split("")
    .filter((s: string) => /\d/.test(s))
    .join("")
    .padStart(3, "0")
  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
  event.target.value = maskCurrency(digitsFloat)
}

export const maskCurrency = (valor: any, locale = 'pt-BR', currency = 'BRL') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor)
}