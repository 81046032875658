import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    Heading,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { Cookies, useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { FaTrash } from 'react-icons/fa';
import axios, { AxiosError } from 'axios';
import FluxoCaixaItem from '../../types/fluxocaixaitem';
import { FormatData } from '../../services/servicesgeneral';

type Props = {
    fluxo: FluxoCaixa,
    handleEdit: any
}

export default function FluxoCaixaEdit({ fluxo, handleEdit }: Props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie] = useCookies();
    const toast = useToast()

    function deleteFluxo(id: number) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "authorization": cookies['authorization'].toString()
            }
        }

        axios['delete'](`${API_URL}fluxo-caixa/${id}`, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Fluxo de caixa excluído com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                handleEdit(id)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao encontrar fluxo de caixa"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                //if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    return (

        <>
            <Button onClick={onOpen}><EditIcon /></Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Fluxo de caixa</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel marginTop={'20px'}>Descrição</FormLabel>
                        <input placeholder={fluxo.descricao} disabled />

                        <FormLabel marginTop={'20px'}>Valor</FormLabel>
                        <input placeholder={fluxo.valor.toString()} disabled />

                        <FormLabel marginTop={'20px'}>Tipo</FormLabel>
                        <input placeholder={fluxo.tipo_fluxo_caixa.nome} disabled />

                        <FormLabel marginTop={'20px'}>Categoria</FormLabel>
                        <input placeholder={fluxo.fluxo_caixa_categoria.name} disabled />

                        <FormLabel marginTop={'20px'}>Tipo Destinatário</FormLabel>
                        <input placeholder={fluxo.tipo_destinatario.nome} disabled />

                        <FormLabel marginTop={'20px'}>Destinatário</FormLabel>
                        <input placeholder={fluxo.destinatario_nome} disabled />

                        <FormLabel marginTop={'20px'}>Criado em</FormLabel>
                        <input placeholder={FormatData(new Date(fluxo.createdAt))} disabled />

                        <Heading size={'md'} fontWeight="medium" marginBottom={'15px'} marginTop={'15px'}>Itens</Heading>

                        {fluxo.fluxo_caixa_itens ? fluxo.fluxo_caixa_itens.length > 0 ? (fluxo.fluxo_caixa_itens?.map((itens: FluxoCaixaItem, index) => (
                            <div>
                            <FormLabel marginTop={'20px'}>{`${itens.itens.nome}`}</FormLabel>
                            <input placeholder={`Quantidade: ${itens.quantidade}`} disabled />
                            </div>
                        ))) : "" : ""}
                    </ModalBody>
                    <ModalFooter marginTop={'20px'}>
                        <Flex width={'100%'} justifyContent={'center'}>
                            <Button onClick={() => deleteFluxo(fluxo.id)}><FaTrash /> Excluir</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )

}


