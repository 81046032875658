import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon} from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import FluxoCaixaEdit from './FluxoCaixaEdit';
import { FormatData } from '../../services/servicesgeneral';

type Props = {
    data: FluxoCaixa[],
    viewValuesParam: boolean,
    handleTable: any,
    total: number,
    tableReference: React.MutableRefObject<null>
}

export default function FluxoCaixaTable({ data, viewValuesParam, handleTable, total, tableReference }: Props) {

    const [isLoading, setLoading] = useState(false)
    const { colorMode } = useColorMode()

    const handleEdit = (id: number): void => {

        var newData: FluxoCaixa[] = data?.filter(fluxo => (fluxo.id !== id));

        handleTable(newData);
    };

    function renderRows() {

        return data?.map((fluxo: any) => (
            <Tr key={fluxo.id} id={fluxo.valor?.toString()} _hover={{
                bg: 'gray.300',
                cursor: 'grab'
            }}>
                <Td whiteSpace={'pre-line'}>{fluxo.descricao}</Td>
                {viewValuesParam ? <Td whiteSpace={'pre-line'}>{viewValuesParam ? fluxo.valor?.toFixed(2) : ''}</Td> : ''}
                <Td whiteSpace={'pre-line'} hideBelow="lg">{fluxo.fluxo_caixa_categoria.name}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="xl">{fluxo.tipo_destinatario.nome}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="xl">{fluxo.destinatario_nome}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="md">{FormatData(new Date(fluxo.createdAt))}</Td>
                <Td whiteSpace={'pre-line'}>
                    <FluxoCaixaEdit fluxo={fluxo} handleEdit={handleEdit}/>
                </Td>
            </Tr>
        ))

    }

    return (
        <Flex width={'100%'}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <TableContainer overflowX={'auto'} width={'100%'} alignItems={'center'}>
                    <Text fontSize={'lg'} color={colorMode === 'light' ? 'black' : 'white'} margin={'15px'}>
                        {viewValuesParam ? 'Total ' + total.toFixed(2) : ''}
                    </Text>
                    <Table variant='simple' colorScheme='gray' size='sm' table-layout='fixed' id='lista' ref={tableReference}>
                        <Thead>
                            <Tr>
                                <Th>Descrição</Th>
                                {viewValuesParam ? <Th>Valor</Th> : ''}
                                <Th hideBelow="lg">Categoria</Th>
                                <Th hideBelow="xl">Tipo de Destinatário</Th>
                                <Th hideBelow="xl">Destinatário</Th>
                                <Th hideBelow="md">Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.length > 0 ? renderRows() : (<p>Não há dados...</p>)}
                        </Tbody>

                        <Tfoot>
                            <Tr>
                                <Th>Descrição</Th>
                                {viewValuesParam ? <Th>Valor</Th>: ''}
                                <Th hideBelow="lg">Categoria</Th>
                                <Th hideBelow="xl">Tipo de Destinatário</Th>
                                <Th hideBelow="xl">Destinatário</Th>
                                <Th hideBelow="md">Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Flex>
    )

}


