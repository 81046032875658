import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    Heading,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa';
import FluxoCaixaTable from '../../components/fluxocaixa/TableFluxoCaixa';
import FluxoCaixaFilter from '../../components/fluxocaixa/FluxoCaixaFilter';
import FluxoCaixaNew from '../../components/fluxocaixa/FluxoCaixaNew';
import Orcamento from '../../types/orcamento';
import OrcamentoNew from '../../components/orcamento/OrcamentoNew';
import OrcamentoTable from '../../components/orcamento/TableOrcamento';
import OrcamentoFilter from '../../components/orcamento/OrcamentoFilter';
import Item from '../../types/item';
import ItemTable from '../../components/item/TableItem';
import ItemNew from '../../components/item/itemNew';

export default function Dashboard() {

    const [cookies, setCookie, removeCookie] = useCookies();

    const authorization = cookies['authorization']

    return (
        <div>

            <Flex
                minH={'85vh'}
                justify={'flex-start'}
            >
                <iframe src={`https://versagerenciamento.streamlit.app/?embed=true&embed_options=dark_theme&session=${authorization}`} 
                width={'100%'}
                height={'auto'}
                title='Dashboard'
                ></iframe>
                
            </Flex>

        </div >
    )

}


