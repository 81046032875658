'use client'
import { API_URL } from '../globals/globals'
import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  FormErrorMessage,
  useToast,
  ColorModeProvider,
} from '@chakra-ui/react'
import axios, { AxiosError } from 'axios'
import md5 from 'md5'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie';

export default function LoginForm() {

  const [cookies, setCookie] = useCookies();
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm()

  const toast = useToast()

  function onSubmit(values: any) {

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
      }
    }

    values['senha'] = md5(values['senha'])

    axios['post'](`${API_URL}login`, JSON.stringify(values), config)
      .then(resp => {

        setCookie("authorization", resp.data.authorization)

        toast({
          title: 'Sucesso',
          description: "Logado com sucesso",
          status: 'success',
          isClosable: true,
          position: 'top',
        })

        window.location.href = "consultar/fluxo-caixa"

      })
      .catch((reason: AxiosError) => {

        var alert = ""

        if (reason.response?.status == 404) alert = "Usuário ou senha inválidos"

        else if (reason.response?.status == 401) alert = "Usuário não autorizado"

        toast({
          title: 'Erro',
          description: alert,
          status: 'error',
          isClosable: true,
          position: 'top',
        })



      })

  }

  return (
    <Stack minH={'100vh'} width={'100%'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} w={'full'} maxW={'md'}>
            <Heading fontSize={'3xl'} marginBottom={'50px'} textAlign={'center'}>Versa Gerenciamento</Heading>
            <FormControl id="login" isInvalid={Boolean(errors?.login)}>
              <FormLabel>Email</FormLabel>
              <Input type="email"
                {...register('login', {
                  required: 'Este campo é obrigatório',
                  minLength: { value: 1, message: 'Este campo é obrigatório' },
                })}
              />
              <FormErrorMessage>
                {errors.login?.toString() && errors.login.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="senha" isInvalid={Boolean(errors?.senha)}>
              <FormLabel>Senha</FormLabel>
              <Input type="password"
                {...register('senha', {
                  required: 'Este campo é obrigatório',
                  minLength: { value: 6, message: 'Mínimo de 6 caracteres' },
                })}
              />
              <FormErrorMessage>
                {errors.senha?.toString() && errors.senha.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Text color={'blue.500'}>Esqueceu a senha?</Text>
              </Stack>
              <Button colorScheme={'blue'} variant={'solid'}
                isLoading={isSubmitting}
                type='submit'>
                Login
              </Button>
            </Stack>
          </Stack>
        </form>
      </Flex>
      <Flex flex={1} hideBelow="md">
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          opacity={'90%'}
          src={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          }
        />
      </Flex>
    </Stack>
  )
}
