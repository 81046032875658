import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormLabel,
    FormControl,
    FormErrorMessage,
    Box,
    Heading,
    Textarea,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { GetDestinatariosGeral, GetEmpresas, GetFluxoCaixaCategoria, GetFuncionarios, GetItens, GetModalidadeFechamento, GetTipoDestinatario } from '../../services/servicesgeneral';
import Empresa from '../../types/empresa';
import Funcionario from '../../types/funcionario';
import DestinatarioGeral from '../../types/destinatariogeral';
import TipoDestinatario from '../../types/tipodestinatario';
import FluxoCaixaCategoria from '../../types/fluxocaixacategoria';
import axios, { AxiosError } from 'axios';
import Item from '../../types/item';
import { FaTrash } from 'react-icons/fa';
import ModalidadeFechamento from '../../types/modalidadefechamento';

type Props = {
    createdFluxo: any
}

export default function FechamentoNew({ createdFluxo }: Props) {

    const [empresas, setEmpresas] = useState<Empresa[] | null>(null)
    const [funcionarios, setFuncionarios] = useState<Funcionario[] | null>(null)
    const [modalidadeFechamento, setModalidadeFechamento] = useState<ModalidadeFechamento[] | null>(null)
    const [itens, setItens] = useState<Item[] | null>(null)
    const [compradorTipoPessoa, setCompradorTipoPessoa] = useState<Number>(0)
    const [vendedorTipoPessoa, setVendedorTipoPessoa] = useState<Number>(0)
    const [armazemRetiradaTipoPessoa, setArmazemRetiradaTipoPessoa] = useState<Number>(0)
    const [armazemDescargaTipoPessoa, setArmazemDescargaTipoPessoa] = useState<Number>(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies()
    const toast = useToast()

    const {
        Select
    } = require("chakra-react-select");

    const {
        handleSubmit,
        register,
        reset,
        control,
        trigger,
        getValues,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    useEffect(() => {

        GetEmpresas(cookies['authorization']).then((data: Empresa[] | any) => {
            setEmpresas(data);
        });

        GetFuncionarios(cookies['authorization']).then((data: Funcionario[] | any) => {
            setFuncionarios(data);
        });

        GetModalidadeFechamento(cookies['authorization']).then((data: Funcionario[] | any) => {
            setModalidadeFechamento(data);
        });

    }, [])

    function onSubmit(values: any) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'authorization': cookies['authorization']
            }
        }

        values['data_retirada'] = new Date(values['data_retirada'])
        values['data_pagamento'] = new Date(values['data_pagamento'])
        values['preco'] = parseFloat(values['preco'])
        values['sacas'] = parseInt(values['sacas'])
        values['corretagens_comprador'] = parseInt(values['corretagens_comprador'])
        values['corretagens_vendedor'] = parseInt(values['corretagens_vendedor'])
        values['comprador_tipo_pessoa'] = parseInt(values['comprador_tipo_pessoa'])
        values['comprador'] = parseInt(values['comprador'])
        values['vendedor_tipo_pessoa'] = parseInt(values['vendedor_tipo_pessoa'])
        values['vendedor'] = parseInt(values['vendedor'])
        values['armazem_retirada_tipo_pessoa'] = parseInt(values['armazem_retirada_tipo_pessoa'])
        values['armazem_retirada'] = parseInt(values['armazem_retirada'])
        values['armazem_descarga_tipo_pessoa'] = parseInt(values['armazem_descarga_tipo_pessoa'])
        values['armazem_descarga'] = parseInt(values['armazem_descarga'])
        values['fechamento_modalidade_id'] = parseInt(values['fechamento_modalidade_id'])

        axios['post'](`${API_URL}fechamentos`, values, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Fluxo de caixa criado com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                createdFluxo(resp.data)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao criar fechamento"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'} justifyContent={'center'}>
                <AddIcon />
                <Text marginLeft={'10px'}>
                    Inserir
                </Text>
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)} id='createFluxoCaixaForm'>
                        <ModalHeader>Novo Fechamento</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl id="comprador_tipo_pessoa" isInvalid={Boolean(errors?.comprador_tipo_pessoa)}>
                                <FormLabel marginTop={'15px'}>Tipo de Comprador</FormLabel>
                                <Select
                                    options={[{ label: "Pessoa Física", value: 1 }, { label: "Pessoa Jurídica", value: 2 }]}
                                    onChange={(choice: any) => {
                                        setValue('comprador_tipo_pessoa', choice.value)
                                        setCompradorTipoPessoa(choice.value)
                                    }}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.comprador_tipo_pessoa?.toString() && errors.comprador_tipo_pessoa.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="comprador" isInvalid={Boolean(errors?.comprador)}>
                                <FormLabel marginTop={'15px'}>Comprador</FormLabel>
                                <Select
                                    options={compradorTipoPessoa != 1 ?
                                        empresas?.map(item => ({ label: `${item.cnpj} - ${item.nome}`, value: item.id }))
                                        :
                                        funcionarios?.map(item => ({ label: `${item.cpf} - ${item.nome}`, value: item.id }))
                                    }
                                    onChange={(choice: any) => setValue('comprador', choice.value)}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.comprador?.toString() && errors.comprador.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="vendedor_tipo_pessoa" isInvalid={Boolean(errors?.vendedor_tipo_pessoa)}>
                                <FormLabel marginTop={'15px'}>Tipo de Vendedor</FormLabel>
                                <Select
                                    options={[{ label: "Pessoa Física", value: 1 }, { label: "Pessoa Jurídica", value: 2 }]}
                                    onChange={(choice: any) => {
                                        setValue('vendedor_tipo_pessoa', choice.value)
                                        setVendedorTipoPessoa(choice.value)
                                    }}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.vendedor_tipo_pessoa?.toString() && errors.vendedor_tipo_pessoa.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="vendedor" isInvalid={Boolean(errors?.vendedor)}>
                                <FormLabel marginTop={'15px'}>Vendedor</FormLabel>
                                <Select
                                    options={vendedorTipoPessoa != 1 ?
                                        empresas?.map(item => ({ label: `${item.cnpj} - ${item.nome}`, value: item.id }))
                                        :
                                        funcionarios?.map(item => ({ label: `${item.cpf} - ${item.nome}`, value: item.id }))
                                    }
                                    onChange={(choice: any) => setValue('vendedor', choice.value)}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.vendedor?.toString() && errors.vendedor.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="armazem_retirada_tipo_pessoa" isInvalid={Boolean(errors?.armazem_retirada_tipo_pessoa)}>
                                <FormLabel marginTop={'15px'}>Tipo de armazém para retirada</FormLabel>
                                <Select
                                    options={[{ label: "Pessoa Física", value: 1 }, { label: "Pessoa Jurídica", value: 2 }]}
                                    onChange={(choice: any) => {
                                        setValue('armazem_retirada_tipo_pessoa', choice.value)
                                        setArmazemRetiradaTipoPessoa(choice.value)
                                    }}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.armazem_retirada_tipo_pessoa?.toString() && errors.armazem_retirada_tipo_pessoa.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="armazem_retirada" isInvalid={Boolean(errors?.armazem_retirada)}>
                                <FormLabel marginTop={'15px'}>Armazém para retirada</FormLabel>
                                <Select
                                    options={armazemRetiradaTipoPessoa != 1 ?
                                        empresas?.map(item => ({ label: `${item.cnpj} - ${item.nome}`, value: item.id }))
                                        :
                                        funcionarios?.map(item => ({ label: `${item.cpf} - ${item.nome}`, value: item.id }))
                                    }
                                    onChange={(choice: any) => setValue('armazem_retirada', choice.value)}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.armazem_retirada?.toString() && errors.armazem_retirada.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="armazem_descarga_tipo_pessoa" isInvalid={Boolean(errors?.armazem_descarga_tipo_pessoa)}>
                                <FormLabel marginTop={'15px'}>Tipo de armazém para descarga</FormLabel>
                                <Select
                                    options={[{ label: "Pessoa Física", value: 1 }, { label: "Pessoa Jurídica", value: 2 }]}
                                    onChange={(choice: any) => {
                                        setValue('armazem_descarga_tipo_pessoa', choice.value)
                                        setArmazemDescargaTipoPessoa(choice.value)
                                    }}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.armazem_descarga_tipo_pessoa?.toString() && errors.armazem_descarga_tipo_pessoa.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="armazem_descarga" isInvalid={Boolean(errors?.armazem_descarga)}>
                                <FormLabel marginTop={'15px'}>Armazém para descarga</FormLabel>
                                <Select
                                    options={armazemDescargaTipoPessoa != 1 ?
                                        empresas?.map(item => ({ label: `${item.cnpj} - ${item.nome}`, value: item.id }))
                                        :
                                        funcionarios?.map(item => ({ label: `${item.cpf} - ${item.nome}`, value: item.id }))
                                    }
                                    onChange={(choice: any) => setValue('armazem_descarga', choice.value)}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.armazem_descarga?.toString() && errors.armazem_descarga.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="sacas" isInvalid={Boolean(errors?.sacas)}>
                                <FormLabel marginTop={'15px'}>Número de Sacas</FormLabel>
                                <Input type="number"
                                    {...register('sacas', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.sacas?.toString() && errors.sacas.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="preco" isInvalid={Boolean(errors?.preco)}>
                                <FormLabel marginTop={'15px'}>Preço</FormLabel>
                                <Input type="number" step="0.01"
                                    {...register('preco', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.preco?.toString() && errors.preco.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="fechamento_modalidade_id" isInvalid={Boolean(errors?.fechamento_modalidade_id)}>
                                <FormLabel marginTop={'15px'}>Modalidade</FormLabel>
                                <Select
                                    options={modalidadeFechamento?.map(item => ({ label: `${item.name}`, value: item.id }))}
                                    onChange={(choice: any) => setValue('fechamento_modalidade_id', choice.value)}
                                ></Select>
                                <FormErrorMessage>
                                    {errors.fechamento_modalidade_id?.toString() && errors.fechamento_modalidade_id.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="descricao" isInvalid={Boolean(errors?.descricao)}>
                                <FormLabel marginTop={'15px'}>Descrição</FormLabel>
                                <Textarea
                                    h='200px'
                                    {...register('descricao', {
                                        required: 'Este campo é obrigatório',
                                        minLength: { value: 4, message: 'Mínimo de 4 caracteres' },
                                    })} />
                                <FormErrorMessage>
                                    {errors.descricao?.toString() && errors.descricao.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="data_retirada" isInvalid={Boolean(errors?.data_retirada)}>
                                <FormLabel marginTop={'15px'}>Data de Retirada</FormLabel>
                                <Input type="date"
                                    {...register('data_retirada', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.data_retirada?.toString() && errors.data_retirada.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="data_pagamento" isInvalid={Boolean(errors?.data_pagamento)}>
                                <FormLabel marginTop={'15px'}>Data de Pagamento</FormLabel>
                                <Input type="date"
                                    {...register('data_pagamento', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.data_pagamento?.toString() && errors.data_pagamento.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="corretagens_comprador" isInvalid={Boolean(errors?.corretagens_comprador)}>
                                <FormLabel marginTop={'15px'}>Corretagens de Comprador ( % )</FormLabel>
                                <Input type="number"
                                    {...register('corretagens_comprador', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.corretagens_comprador?.toString() && errors.corretagens_comprador.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="corretagens_vendedor" isInvalid={Boolean(errors?.corretagens_vendedor)}>
                                <FormLabel marginTop={'15px'}>Corretagens de Vendedor ( % )</FormLabel>
                                <Input type="number"
                                    {...register('corretagens_vendedor', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.corretagens_vendedor?.toString() && errors.corretagens_vendedor.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="corretagens_observacao" isInvalid={Boolean(errors?.corretagens_observacao)}>
                                <FormLabel marginTop={'15px'}>Observação de Corretagem</FormLabel>
                                <Textarea
                                    h='200px'
                                    {...register('corretagens_observacao', {
                                        required: 'Este campo é obrigatório',
                                        minLength: { value: 4, message: 'Mínimo de 4 caracteres' },
                                    })} />
                                <FormErrorMessage>
                                    {errors.corretagens_observacao?.toString() && errors.corretagens_observacao.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter marginTop={'20px'}>
                            <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <Button type='submit'>Inserir</Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )

}


