import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'

type Props = {
    data: FluxoCaixa[],
    handleFilter: any
}

export default function FluxoCaixaFilter({ data, handleFilter }: Props) {

    const [dataInicial, setdataInicial] = useState<FluxoCaixa[] | null>(null)
    const [destinatarioNome, setdestinatarioNome] = useState<string[] | null>(null)
    const [FluxoCaixaCategoria, setFluxoCaixaCategoria] = useState<string[] | null>(null)
    const [tipoDestinatario, setTipodestinatario] = useState<string[] | null>(null)
    const [tipoFluxoCaixa, setTipoFluxoCaixa] = useState<string[] | null>(null)
    const [newData, setnewData] = useState<FluxoCaixa[] | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setdataInicial(data)
        setdestinatarioNome([...new Set(data?.map(item => item.destinatario_nome))].sort())
        setFluxoCaixaCategoria([...new Set(data?.map(item => item.fluxo_caixa_categoria.name))].sort())
        setTipodestinatario([...new Set(data?.map(item => item.tipo_destinatario.nome))].sort())
        setTipoFluxoCaixa([...new Set(data?.map(item => item.tipo_fluxo_caixa.nome))].sort())
    }, [])

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'}>Filtrar</Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Filtros</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel marginTop={'15px'}>Tipo Fluxo</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: FluxoCaixa[] = data?.filter(fluxo => (fluxo.tipo_fluxo_caixa.nome == (event!.target as HTMLInputElement).value));

                                    setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                    setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                    setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                    setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {tipoFluxoCaixa?.map((tipo: string) => (
                                <option value={tipo}>{tipo}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Categoria</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: FluxoCaixa[] = data?.filter(fluxo => (fluxo.fluxo_caixa_categoria.name == (event!.target as HTMLInputElement).value));

                                    setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                    setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                    setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                    setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {FluxoCaixaCategoria?.map((categoria: string) => (
                                <option value={categoria}>{categoria}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Tipo de Destinatário</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: FluxoCaixa[] = data?.filter(fluxo => (fluxo.tipo_destinatario.nome == (event!.target as HTMLInputElement).value));

                                    setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                    setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                    setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                    setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {tipoDestinatario?.map((tipo: string) => (
                                <option value={tipo}>{tipo}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Destinatário</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: FluxoCaixa[] = data?.filter(fluxo => (fluxo.destinatario_nome == (event!.target as HTMLInputElement).value));

                                    setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                    setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                    setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                    setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {destinatarioNome?.map((destinatario: string) => (
                                <option value={destinatario}>{destinatario}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Data Mínima</FormLabel>
                        <Input type="date" onChange={() => {

                            if (data) {

                                // eslint-disable-next-line no-restricted-globals
                                const minDate = new Date((event!.target as HTMLInputElement).value)
                                var newDataa: FluxoCaixa[] = data?.filter(fluxo => (new Date(fluxo.createdAt) >= minDate));

                                setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                handleFilter(newDataa)

                            }

                        }} />
                        <FormLabel marginTop={'15px'}>Data Máxima</FormLabel>
                        <Input type="date" onChange={() => {

                            if (data) {

                                // eslint-disable-next-line no-restricted-globals
                                const maxDate = new Date((event!.target as HTMLInputElement).value)
                                var newDataa: FluxoCaixa[] = data?.filter(fluxo => (new Date(fluxo.createdAt) <= maxDate));

                                setdestinatarioNome([...new Set(newDataa?.map(item => item.destinatario_nome))].sort())
                                setFluxoCaixaCategoria([...new Set(newDataa?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                setTipodestinatario([...new Set(newDataa?.map(item => item.tipo_destinatario.nome))].sort())
                                setTipoFluxoCaixa([...new Set(newDataa?.map(item => item.tipo_fluxo_caixa.nome))].sort())

                                handleFilter(newDataa)

                            }

                        }} />
                    </ModalBody>
                    <ModalFooter marginTop={'20px'}>
                        {/* <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}> */}
                            <Button onClick={() => {
                                setdestinatarioNome([...new Set(dataInicial?.map(item => item.destinatario_nome))].sort())
                                setFluxoCaixaCategoria([...new Set(dataInicial?.map(item => item.fluxo_caixa_categoria.name))].sort())
                                setTipodestinatario([...new Set(dataInicial?.map(item => item.tipo_destinatario.nome))].sort())
                                setTipoFluxoCaixa([...new Set(dataInicial?.map(item => item.tipo_fluxo_caixa.nome))].sort())
                                handleFilter(dataInicial)
                            }}>Limpar</Button>
                            {/* <Button onClick={() => handleFilter(newData)}>Aplicar</Button> */}
                        {/* </Flex> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )

}


