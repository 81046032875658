import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import FluxoCaixaEdit from './OrcamentoEdit';
import Orcamento from '../../types/orcamento';
import OrcamentoEdit from './OrcamentoEdit';

type Props = {
    data: Orcamento[],
    handleTable: any,
    tableReference: React.MutableRefObject<null>
}

export default function OrcamentoTable({ data, handleTable, tableReference }: Props) {

    const [isLoading, setLoading] = useState(false)
    const { colorMode } = useColorMode()

    const handleEdit = (id: number): void => {

        var newData: Orcamento[] = data?.filter(fluxo => (fluxo.id !== id));

        handleTable(newData);
    };

    function renderRows() {

        return data?.map((orcamento: Orcamento) => (
            <Tr key={orcamento.id}>
                <Td whiteSpace={'pre-line'} hideBelow={'lg'}>{orcamento.empresas.nome + ' - ' + orcamento.empresas.cnpj}</Td>
                <Td whiteSpace={'pre-line'}>{orcamento.itens.nome}</Td>
                <Td whiteSpace={'pre-line'}>{orcamento.quantidade}</Td>
                <Td whiteSpace={'pre-line'}>{orcamento.valor_un}</Td>
                <Td whiteSpace={'pre-line'} hideBelow={'lg'}>{orcamento.createdAt.replace(/T/, ' ').replace(/\..+/, '')}</Td>
                <Td whiteSpace={'pre-line'}>
                    <OrcamentoEdit orcamento={orcamento} handleEdit={handleEdit} />
                </Td>
            </Tr>
        ))

    }

    return (
        <Flex width={'100%'}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <TableContainer overflowX={'auto'} width={'100%'} alignItems={'center'}>
                    <Table variant='simple' colorScheme='gray' size='sm' table-layout='fixed' id='lista' ref={tableReference}>
                        <Thead>
                            <Tr>
                                <Th hideBelow="lg">Empresa</Th>
                                <Th>Item</Th>
                                <Th>quantidade</Th>
                                <Th>Valor</Th>
                                <Th hideBelow={'lg'}>Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.length > 0 ? renderRows() : (<p>Não há dados...</p>)}
                        </Tbody>

                        <Tfoot>
                            <Tr>
                                <Th hideBelow="lg">Empresa</Th>
                                <Th>Item</Th>
                                <Th>quantidade</Th>
                                <Th>Valor</Th>
                                <Th hideBelow={'lg'}>Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Flex>
    )

}


