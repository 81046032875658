import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { useForm } from 'react-hook-form';
import { GetDestinatariosGeral, GetEmpresas, GetFluxoCaixaCategoria, GetFuncionarios, GetItens, GetTipoDestinatario, mascaraMoeda } from '../../services/servicesgeneral';
import Empresa from '../../types/empresa';
import Funcionario from '../../types/funcionario';
import DestinatarioGeral from '../../types/destinatariogeral';
import TipoDestinatario from '../../types/tipodestinatario';
import FluxoCaixaCategoria from '../../types/fluxocaixacategoria';
import axios, { AxiosError } from 'axios';
import Item from '../../types/item';

type Props = {
    createdOrcamento: any
}

export default function OrcamentoNew({createdOrcamento}: Props) {

    const [empresas, setEmpresas] = useState<Empresa[] | null>(null)
    const [itens, setItens] = useState<Item[] | null>(null)
    const [fluxoCaixaCategoria, setfluxoCaixaCategoria] = useState<FluxoCaixaCategoria[] | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies()
    const toast = useToast()

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm()

    useEffect(() => {

        GetEmpresas(cookies['authorization']).then((data: Empresa[] | any) => {
            setEmpresas(data);
        });

        GetItens(cookies['authorization']).then((data: Item[] | any) => {
            setItens(data);
        });

    }, [])

    function onSubmit(values: any) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'authorization': cookies['authorization']
            }
        }

        values['empresas_id'] = parseInt(values['empresas_id'])
        values['itens_id'] = parseInt(values['itens_id'])

        axios['post'](`${API_URL}orcamentos`, values, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Orçamento criado com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                createdOrcamento(resp.data)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao criar orçamento"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'} justifyContent={'center'}>
                <AddIcon />
                <Text marginLeft={'10px'}>
                    Inserir
                </Text>
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)} id='createFluxoCaixaForm'>
                        <ModalHeader>Novo Orçamento</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        <FormControl id="empresas_id" isInvalid={Boolean(errors?.empresas_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Empresa</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('empresas_id', {
                                    required: 'Este campo é obrigatório',
                                })}>
                                {empresas ? empresas.length > 0 ? (empresas?.map((empresa: Empresa) => (
                                    <option value={empresa.id}>{empresa.nome + ' - ' + empresa.cnpj}</option>
                                )) ) : (<option disabled>Não há empresas cadastradas</option>) : (<option disabled>Não há empresas cadastradas</option>)}
                            </Select>
                            <FormErrorMessage>
                                {errors.empresas_id?.toString() && errors.empresas_id.message?.toString()}
                            </FormErrorMessage>
                            <FormControl id="itens_id" isInvalid={Boolean(errors?.itens_id)}></FormControl>
                            <FormLabel marginTop={'15px'}>Item</FormLabel>
                            <Select placeholder='Selecione...'
                                {...register('itens_id', {
                                    required: 'Este campo é obrigatório',
                                })}>
                                {itens ? itens.length > 0 ? (itens?.map((itens: Item) => (
                                    <option value={itens.id}>{itens.nome}</option>
                                )) ) : (<option disabled>Não há itens cadastrados</option>) : (<option disabled>Não há itens cadastrados</option>)}
                            </Select>
                            <FormErrorMessage>
                                {errors.itens_id?.toString() && errors.itens_id.message?.toString()}
                            </FormErrorMessage>
                            <FormControl id="quantidade" isInvalid={Boolean(errors?.quantidade)}>
                                <FormLabel marginTop={'15px'}>Quantidade</FormLabel>
                                <Input type="number" step="0.01"
                                    {...register('quantidade', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.quantidade?.toString() && errors.quantidade.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl id="valor_un" isInvalid={Boolean(errors?.valor_un)}>
                                <FormLabel marginTop={'15px'}>Valor</FormLabel>
                                <Input type="text"
                                    // eslint-disable-next-line no-restricted-globals
                                    onInput={() => mascaraMoeda(event)}
                                    {...register('valor_un', {
                                        required: 'Este campo é obrigatório',
                                    })} />
                                <FormErrorMessage>
                                    {errors.valor_un?.toString() && errors.valor_un.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter marginTop={'20px'}>
                            <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <Button type='submit'>Inserir</Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )

}


