import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { Cookies, useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { FaTrash } from 'react-icons/fa';
import axios, { AxiosError } from 'axios';
import Orcamento from '../../types/orcamento';

type Props = {
    orcamento: Orcamento,
    handleEdit: any
}

export default function FluxoCaixaEdit({ orcamento, handleEdit }: Props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie] = useCookies();
    const toast = useToast()

    function deleteOrcamento(id: number){

        const config = {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "authorization": cookies['authorization'].toString()
          }
      }
    
      axios['delete'](`${API_URL}orcamentos/${id}`, config)
          .then(resp => {
    
            toast({
                title: 'Sucesso',
                description: "Orçamento excluído com sucesso",
                status: 'success',
                isClosable: true,
                position: 'top',
            })

            handleEdit(id)

            onClose()
    
          })
          .catch((reason: AxiosError) => {
    
            var alert = ""
    
            if(reason.response?.status == 404) alert = "Erro ao encontrar orçamento"
    
            else if(reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"
    
              toast({
                title: 'Erro',
                description: alert,
                status: 'error',
                isClosable: true,
                position: 'top',
            })
    
            //if (reason.response?.status == 401) window.location.href = FRONT_URL
    
          })
    
      }

    return (

        <>
            <Button onClick={onOpen}><EditIcon /></Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel marginTop={'20px'}>Empresa</FormLabel>
                        <input placeholder={orcamento.empresas.nome.toString()} disabled/>

                        <FormLabel marginTop={'20px'}>Item</FormLabel>
                        <input placeholder={orcamento.itens.nome.toString()} disabled/>

                        <FormLabel marginTop={'20px'}>Quantidade</FormLabel>
                        <input placeholder={orcamento.quantidade} disabled/>

                        <FormLabel marginTop={'20px'}>Valor</FormLabel>
                        <input placeholder={orcamento.valor_un} disabled/>

                        <FormLabel marginTop={'20px'}>Criado em</FormLabel>
                        <input placeholder={orcamento.createdAt.replace(/T/, ' ').replace(/\..+/, '')} disabled/>
                    </ModalBody>
                    <ModalFooter marginTop={'20px'}>
                        <Flex width={'100%'} justifyContent={'center'}>
                            <Button onClick={() => deleteOrcamento(orcamento.id)}><FaTrash /> Excluir</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )

}


