import * as React from "react"
import {
  ChakraProvider,
  ColorModeScript,
  DarkMode,
  Flex,
  useColorMode,
} from "@chakra-ui/react"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login'
import Nav from "./templates/nav";
import ConsultaFluxoCaixa from "./pages/fluxocaixa/ConsultaFluxoCaixa";
import SidebarWithHeader from "./templates/sidebar";
import theme from "./theme";

const urls = ["http://localhost:3000/", "https://cafe.versasolution.com.br/"]

export const App = () => (
  <ChakraProvider theme={theme}>
    {urls.includes(window.location.href) ? '' : <SidebarWithHeader/>}
    <Flex position={'relative'}>
    <BrowserRouter>
    <Routes>
			<Route path="/" element={<Login/>} />
		</Routes>
    </BrowserRouter>
    </Flex>
  </ChakraProvider>
)
