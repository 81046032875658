import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import { useForm } from 'react-hook-form';
import { GetDestinatariosGeral, GetEmpresas, GetFluxoCaixaCategoria, GetFuncionarios, GetItens, GetTipoDestinatario, mascaraMoeda } from '../../services/servicesgeneral';
import Empresa from '../../types/empresa';
import Funcionario from '../../types/funcionario';
import DestinatarioGeral from '../../types/destinatariogeral';
import TipoDestinatario from '../../types/tipodestinatario';
import FluxoCaixaCategoria from '../../types/fluxocaixacategoria';
import axios, { AxiosError } from 'axios';
import Item from '../../types/item';

type Props = {
    createdItem: any
}

export default function FluxoCaixaCategoriaNew({createdItem}: Props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies()
    const toast = useToast()

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm()

    function onSubmit(values: any) {

        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'authorization': cookies['authorization']
            }
        }

        axios['post'](`${API_URL}fluxo-categoria`, values, config)
            .then(resp => {

                toast({
                    title: 'Sucesso',
                    description: "Categoria criada com sucesso",
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                })

                createdItem(resp.data)

                onClose()

            })
            .catch((reason: AxiosError) => {

                var alert = ""

                if (reason.response?.status == 404) alert = "Erro ao criar categoria"

                else if (reason.response?.status == 401) alert = "Sessão expirada, faça login novamente"

                toast({
                    title: 'Erro',
                    description: alert,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                if (reason.response?.status == 401) window.location.href = FRONT_URL

            })

    }

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'} justifyContent={'center'}>
                <AddIcon />
                <Text marginLeft={'10px'}>
                    Inserir
                </Text>
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)} id='createFluxoCaixaForm'>
                        <ModalHeader>Nova Categoria</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        <FormControl id="name" isInvalid={Boolean(errors?.name)}></FormControl>
                            <FormLabel marginTop={'15px'}>Nome</FormLabel>
                            <Input type='text'
                                {...register('name', {
                                    required: 'Este campo é obrigatório',
                                })}/>
                            <FormErrorMessage>
                                {errors.name?.toString() && errors.name.message?.toString()}
                            </FormErrorMessage>
                        </ModalBody>
                        <ModalFooter marginTop={'20px'}>
                            <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <Button type='submit'>Inserir</Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )

}


