import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import { Flex, useToast } from '@chakra-ui/react';
import { FormatData, FormatDataSemHora } from '../../services/servicesgeneral';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingTop: 10,
    fontSize: 10,
    fontFamily: 'Helvetica',
    lineHeight: 1.6,
    backgroundColor: '#f5f5f5', // Fundo da página
  },
  headerView: {
    display: 'flex',
    alignItems: 'center'
  },
  headerImgView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerImg: {
    height: '60px',
    width: '180px'
  },
  txt: {
    fontSize: 8
  },
  header: {
    paddingTop: '5px',
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 10,
    textTransform: 'uppercase',
    backgroundColor: '#e0e0e0', // Fundo do cabeçalho
    borderRadius: 4,
  },
  section: {
    borderBottom: '1px solid #333',
    padding: 6,
    paddingBottom: 0,
    paddingTop: 3,
    marginBottom: 10,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    boxShadow: '0px 1px 3px rgba(0,0,0,0.1)', // Sombra leve
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  sameRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    width: '48%',
  },
  textBold: {
    fontWeight: 'bold',
  },
  underline: {
    borderBottom: '1px solid #333',
    paddingBottom: 0,
    marginBottom: 2,
  },
  footer: {
    marginTop: 25,
    textAlign: 'center',
    fontSize: 10,
    fontStyle: 'italic',
  },
  footerSign: {
    marginTop: 25,
  },
  sectionTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 4,
    borderBottom: '1px solid #333',
    paddingBottom: 0,
    textTransform: 'uppercase',
  },
});

// Create Document Component
export const ContractPDF = () => {

  const [cookies, setCookie, removeCookie] = useCookies();
  const toast = useToast()
  const [data, setData] = useState<any | null>(null)
  const { id } = useParams()

  useEffect(() => {

    fetch(`${API_URL}fechamentos/${id}`, {
      method: "GET",
      headers: {
        authorization: cookies['authorization']
      },
    }).then(function (res) {

      if (res.status == 401) {

        toast({
          title: 'Erro',
          description: "Sessão expirada, faça login novamente",
          status: 'error',
          isClosable: true,
          position: 'top',
        })

        removeCookie('authorization')

        window.location.href = FRONT_URL

      } else {
        return res.json()
      }

    }).then((data) => {

      console.log(data)
      setData(data)

    })

  }, [id])

  if (data) {

    return (
      <PDFViewer width={'100%'} height={'600px'}>
        <Document>
          <Page style={styles.page}>
            <View style={styles.headerImgView}>
              <Image src="https://i.ibb.co/DKWWhPP/verdesafra.png" style={styles.headerImg} />
            </View>
            {/* Título */}
            <Text style={styles.header}>Confirmação de Negócio</Text>

            {/* Informações Gerais */}
            <View style={styles.infoRow}>
              <View style={styles.column}>
                <Text style={styles.underline}>{`Data: ${FormatDataSemHora(new Date(data.createdAt))}`}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.underline}>{`Nº: ${data.numero}-${new Date(data.createdAt).getFullYear()}`}</Text>
              </View>
            </View>

            {/* Comprador */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Comprador</Text>

              {data.comprador_tipo_pessoa != 1 ?
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CNPJ:</Text> {data.comprador_info.cnpj}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Inscrição Estadual:</Text> {data.comprador_info.inscricao_estadual}</Text>
                    </View>
                  </View>
                )
                :
                (
                  <View>
                    <Text style={styles.txt}><Text style={styles.textBold}>CPF:</Text> {data.comprador_info.cpf}</Text>
                  </View>
                )}
              <Text style={styles.txt}><Text style={styles.textBold}>Comprador:</Text> {data.comprador_info.nome}</Text>
              <Text style={styles.txt}><Text style={styles.textBold}>Endereço:</Text>
                {` ${data.comprador_info.logradouro}, ${data.comprador_info.numero} - ${data.comprador_info.bairro}, ${data.comprador_info.cidade}, ${data.comprador_info.estado} - ${data.comprador_info.cep}`}
              </Text>
            </View>

            {/* Vendedor */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Vendedor</Text>

              {data.vendedor_tipo_pessoa != 1 ?
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CNPJ:</Text> {data.vendedor_info.cnpj}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Inscrição Estadual:</Text> {data.vendedor_info.inscricao_estadual}</Text>
                    </View>
                  </View>
                )
                :
                (
                  <View>
                    <Text style={styles.txt}><Text style={styles.textBold}>CPF:</Text> {data.vendedor_info.cpf}</Text>
                  </View>
                )}
              <Text style={styles.txt}><Text style={styles.textBold}>Vendedor:</Text> {data.vendedor_info.nome}</Text>
              <Text style={styles.txt}><Text style={styles.textBold}>Endereço:</Text>
                {` ${data.vendedor_info.logradouro}, ${data.vendedor_info.numero} - ${data.vendedor_info.bairro}, ${data.vendedor_info.cidade}, ${data.vendedor_info.estado} - ${data.vendedor_info.cep}`}
              </Text>
            </View>

            {/* Armazém para Retirada */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Local de Retirada</Text>

              {data.armazem_retirada_tipo_pessoa != 1 ?
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CNPJ:</Text> {data.armazem_retirada_info.cnpj}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Inscrição Estadual:</Text> {data.armazem_retirada_info.inscricao_estadual}</Text>
                    </View>
                  </View>
                )
                :
                (
                  <View>
                    <Text style={styles.txt}><Text style={styles.textBold}>CPF:</Text> {data.armazem_retirada_info.cpf}</Text>
                  </View>
                )}
              <Text style={styles.txt}><Text style={styles.textBold}>Local de Retirada:</Text> {data.armazem_retirada_info.nome}</Text>
              <Text style={styles.txt}><Text style={styles.textBold}>Endereço:</Text>
                {` ${data.armazem_retirada_info.logradouro}, ${data.armazem_retirada_info.numero} - ${data.armazem_retirada_info.bairro}, ${data.armazem_retirada_info.cidade}, ${data.armazem_retirada_info.estado} - ${data.armazem_retirada_info.cep}`}
              </Text>
            </View>

            <br /><br />

            {/* Armazém para Descarga */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Local de Descarga</Text>

              {data.armazem_descarga_tipo_pessoa != 1 ?
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CNPJ:</Text> {data.armazem_descarga_info.cnpj}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Inscrição Estadual:</Text> {data.armazem_descarga_info.inscricao_estadual}</Text>
                    </View>
                  </View>
                )
                :
                (
                  <View>
                    <Text style={styles.txt}><Text style={styles.textBold}>CPF:</Text> {data.armazem_descarga_info.cpf}</Text>
                  </View>
                )}
              <Text style={styles.txt}><Text style={styles.textBold}>Local de Descarga:</Text> {data.armazem_descarga_info.nome}</Text>
              <Text style={styles.txt}><Text style={styles.textBold}>Endereço:</Text>
                {` ${data.armazem_descarga_info.logradouro}, ${data.armazem_descarga_info.numero} - ${data.armazem_descarga_info.bairro}, ${data.armazem_descarga_info.cidade}, ${data.armazem_descarga_info.estado} - ${data.armazem_descarga_info.cep}`}
              </Text>
            </View>

            {/* Condições */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Condições</Text>
              <View style={styles.sameRow}>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Sacas:</Text> {data.sacas}</Text>
                  <Text style={styles.txt}><Text style={styles.textBold}>Preço:</Text> {`R$ ${parseFloat(data.preco).toFixed(2)}`}</Text>
                  <Text style={styles.txt}><Text style={styles.textBold}>Modalidade:</Text> {data.fechamento_modalidade.name}</Text>
                  <Text style={styles.txt}><Text style={styles.textBold}>Descrição:</Text> {data.descricao}</Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Data de Retirada:</Text> {FormatDataSemHora(new Date(data.data_retirada))}</Text>
                  <Text style={styles.txt}><Text style={styles.textBold}>Data de Pagamento:</Text> {FormatDataSemHora(new Date(data.data_pagamento))}</Text>
                </View>
              </View>
            </View>

            {/* Corretagens */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Corretagens</Text>
              <View style={styles.sameRow}>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Comprador:</Text> {`${data.corretagens_comprador}%`}</Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Vendedor:</Text> {`${data.corretagens_vendedor}%`}</Text>
                </View>
              </View>
              <Text style={styles.txt}><Text style={styles.textBold}>Observação:</Text> {data.corretagens_observacao}</Text>
            </View>

            {/* Dados Bancários */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Dados Bancários</Text>
              <View style={styles.sameRow}>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Favorecido:</Text> {data.vendedor_info.nome}</Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.txt}><Text style={styles.textBold}>Agência:</Text> {data.vendedor_info.agencia}</Text>
                </View>
              </View>
              {data.vendedor_tipo_pessoa != 1 ?
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CNPJ:</Text> {data.vendedor_info.cnpj}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Conta Corrente:</Text> {data.vendedor_info.conta_corrente}</Text>
                    </View>
                  </View>
                )
                :
                (
                  <View style={styles.sameRow}>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>CPF:</Text> {data.vendedor_info.cpf}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.txt}><Text style={styles.textBold}>Conta Corrente:</Text> {data.vendedor_info.conta_corrente}</Text>
                    </View>
                  </View>
                )}
              <Text style={styles.txt}><Text style={styles.textBold}>Banco:</Text> {data.vendedor_info.banco}</Text>
            </View>

            {/* Observação no Rodapé */}
            <View style={styles.footer}>
              <View style={styles.sameRow}>
                <View style={styles.column}>
                  <Text>_________________________________________________</Text>
                  <Text style={styles.txt}>{data.comprador_info.nome}</Text>
                </View>
                <View style={styles.column}>
                  <Text>_________________________________________________</Text>
                  <Text style={styles.txt}>{data.vendedor_info.nome}</Text>
                </View>
              </View>
              <View style={styles.footerSign}>
                <Text>_________________________________________________</Text>
                <Text style={styles.txt}>Verde Safra Comissária e Negócios</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer >
    )
  } else {
    return (<Flex
      minH={'100vh'}
      align={'flex-start'}
      justify={'center'}
      padding={'20px'}
    >
      <p>Carregando...</p>
    </Flex>)
  }

}