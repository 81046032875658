import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
    Heading,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa';
import FluxoCaixaTable from '../../components/fluxocaixa/TableFluxoCaixa';
import FluxoCaixaFilter from '../../components/fluxocaixa/FluxoCaixaFilter';
import FluxoCaixaNew from '../../components/fluxocaixa/FluxoCaixaNew';
import FechamentoTable from '../../components/fechamento/TableFechamento';
import FechamentoNew from '../../components/fechamento/FechamentoNew';
import FechamentoFilter from '../../components/fechamento/FechamentoFilter';

export default function ConsultaFechamento() {

    const [data, setData] = useState<any[] | null>(null)
    const [dataInicial, setDataInicial] = useState<any[] | null>(null)
    const [isLoading, setLoading] = useState(false)
    const [viewValues, setViewValues] = useState(true)
    const tableRef = useRef(null)
    const [cookies, setCookie, removeCookie] = useCookies();
    const toast = useToast()

    const handleFilter = (fluxos: any[]): void => {

        setData(fluxos);

    };

    const createdFluxo = (fluxo: any): void => {

        if(data){

        var dataCompare = data.slice();
        dataCompare.unshift(fluxo);
        setData(dataCompare)

        }

    };

    function viewValuesChange() {
        if (viewValues) {
            setViewValues(false)
        } else {
            setViewValues(true)
        }
    }

    useEffect(() => {
        setLoading(true)

        fetch(`${API_URL}fechamentos`, {
            method: "GET",
            headers: {
                authorization: cookies['authorization']
            },
        }).then(function (res) {

            if (res.status == 401) {

                toast({
                    title: 'Erro',
                    description: "Sessão expirada, faça login novamente",
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                })

                removeCookie('authorization')

                window.location.href = FRONT_URL

            } else {
                return res.json()
            }

        }).then((data) => {
            
            setData(data)
            setDataInicial(data)
            setLoading(false)

        })
    }, [])

    if (data !== null && data[0]) {

        return (
            <div>
                {isLoading ? (
                    <Flex
                        minH={'100vh'}
                        align={'flex-start'}
                        justify={'center'}
                        padding={'20px'}
                    >
                        <p>Carregando...</p>
                    </Flex>

                ) : (
                    <Flex
                        minH={'100vh'}
                        align={'center'}
                        justify={'flex-start'}
                        padding={'20px'}
                        flexDirection={'column'}
                    >
                        <Heading size={'2xl'} fontWeight="medium">Fechamentos</Heading>
                        <Flex
                            width={'100%'}
                            marginTop={'25px'}
                        >
                            <FechamentoNew createdFluxo={createdFluxo} />
                            {/* <FechamentoFilter data={data} handleFilter={handleFilter} /> */}
                            <DownloadTableExcel
                                filename="Fechamentos"
                                sheet="Fechamentos"
                                currentTableRef={tableRef.current}
                            >

                                <Button marginLeft={'10px'} marginBottom={'5px'}> Excel </Button>

                            </DownloadTableExcel>
                        </Flex>
                        <Flex
                            width={'100%'}
                            align={'flex-start'}
                            justify={'center'}
                        >
                            <FechamentoTable data={data} viewValuesParam={viewValues} handleTable={handleFilter} tableReference={tableRef} />
                        </Flex>
                    </Flex>
                )
                }
            </div >
        )

    } else {
        return (
            <div>
                <Flex
                    minH={'100vh'}
                    align={'center'}
                    justify={'flex-start'}
                    padding={'20px'}
                    flexDirection={'column'}
                >
                    <Heading size={'2xl'} fontWeight="medium">Fluxo de Caixa</Heading>
                    <Flex
                        width={'100%'}
                        marginTop={'25px'}
                    >
                        <FluxoCaixaNew createdFluxo={createdFluxo} />
                    </Flex>
                    <Text marginTop={'200px'}>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : (
                        <p>Não há dados...</p>
                    )}
                    </Text>
                </Flex>
            </div>

        )

    }

}


