import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon} from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL, FRONT_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import FluxoCaixaEdit from './FechamentoEdit';
import { FormatData } from '../../services/servicesgeneral';
import { FaFilePdf } from 'react-icons/fa';

type Props = {
    data: any[],
    viewValuesParam: boolean,
    handleTable: any,
    tableReference: React.MutableRefObject<null>
}

export default function FechamentoTable({ data, viewValuesParam, handleTable, tableReference }: Props) {

    const [isLoading, setLoading] = useState(false)
    const { colorMode } = useColorMode()

    const handleEdit = (id: number): void => {

        var newData: any[] = data?.filter(fluxo => (fluxo.id !== id));

        handleTable(newData);
    };

    function renderRows() {

        return data?.map((fluxo: any) => (
            <Tr key={fluxo.id}>
                <Td whiteSpace={'pre-line'}>{`${fluxo.numero}-${new Date(fluxo.createdAt).getFullYear()}`}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="lg">{fluxo.comprador_info.nome}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="md">{fluxo.vendedor_info.nome}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="md">{"R$ "+fluxo.preco}</Td>
                <Td whiteSpace={'pre-line'} hideBelow="md">{FormatData(new Date(fluxo.createdAt))}</Td>
                <Td whiteSpace={'pre-line'}>
                    <Button onClick={() => window.location.href = `${FRONT_URL}fechamento/pdf/${fluxo.id}`}><FaFilePdf /></Button>
                    {/* <FluxoCaixaEdit fluxo={fluxo} handleEdit={handleEdit}/> */}
                </Td>
            </Tr>
        ))

    }

    return (
        <Flex width={'100%'}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <TableContainer overflowX={'auto'} width={'100%'} alignItems={'center'}>
                    <Table variant='simple' colorScheme='gray' size='sm' table-layout='fixed' id='lista' ref={tableReference}>
                        <Thead>
                            <Tr>
                                <Th>Número</Th>
                                <Th>Comprador</Th>
                                <Th hideBelow="md">Vendedor</Th>
                                <Th hideBelow="md">Valor</Th>
                                <Th>Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.length > 0 ? renderRows() : (<p>Não há dados...</p>)}
                        </Tbody>

                        <Tfoot>
                            <Tr>
                            <Th>Número</Th>
                                <Th>Comprador</Th>
                                <Th hideBelow="md">Vendedor</Th>
                                <Th hideBelow="md">Valor</Th>
                                <Th>Criado em</Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Flex>
    )

}


