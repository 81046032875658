import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { EditIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import FluxoCaixaEdit from './DestinatarioEdit';
import Orcamento from '../../types/orcamento';
import OrcamentoEdit from './DestinatarioEdit';
import Item from '../../types/item';
import ItemEdit from './DestinatarioEdit';
import DestinatarioEdit from './DestinatarioEdit';

type Props = {
    data: Item[],
    handleTable: any,
    tableReference: React.MutableRefObject<null>
}

export default function DestinatarioTable({ data, handleTable, tableReference }: Props) {

    const [isLoading, setLoading] = useState(false)
    const { colorMode } = useColorMode()

    const handleEdit = (id: number): void => {

        var newData: Item[] = data?.filter(fluxo => (fluxo.id !== id));

        handleTable(newData);
    };

    const handleUpdate = (item: Item): void => {

        var newData: Item[] = data?.filter(fluxo => (fluxo.id !== item.id));

        newData.unshift(item)

        newData.sort(function(a, b) {
              return a.nome.localeCompare(b.nome);
          });

        handleTable(newData);
    };

    function renderRows() {

        return data?.map((item: Item) => (
            <Tr key={item.id}>
                <Td whiteSpace={'pre-line'}>{item.nome}</Td>
                <Td whiteSpace={'pre-line'}>
                    <DestinatarioEdit item={item} handleEdit={handleEdit} handleUpdate={handleUpdate}/>
                </Td>
            </Tr>
        ))

    }

    return (
        <Flex width={'100%'}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <TableContainer overflowX={'auto'} width={'100%'} alignItems={'center'}>
                    <Table variant='simple' colorScheme='gray' size='sm' table-layout='fixed' id='lista' ref={tableReference}>
                        <Thead>
                            <Tr>
                                <Th >Nome</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.length > 0 ? renderRows() : (<p>Não há dados...</p>)}
                        </Tbody>

                        <Tfoot>
                            <Tr>
                                <Th >Nome</Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Flex>
    )

}


