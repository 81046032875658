import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Input,
    Button,
    useColorMode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormLabel,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import {
    useToast
} from '@chakra-ui/react'
import Nav from '../../templates/nav';
import { useEffect, useState } from "react"
import retiraAcentos from '../../functions/tiraacento';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { API_URL } from '../../globals/globals';
import { useCookies } from 'react-cookie';
import FluxoCaixa from '../../types/fluxocaixa'
import Orcamento from '../../types/orcamento';

type Props = {
    data: Orcamento[],
    handleFilter: any
}

export default function OrcamentoFilter({ data, handleFilter }: Props) {

    const [dataInicial, setdataInicial] = useState<Orcamento[] | null>(null)
    const [destinatarioNome, setdestinatarioNome] = useState<string[] | null>(null)
    const [empresas, setEmpresas] = useState<string[] | null>(null)
    const [itens, setItens] = useState<string[] | null>(null)
    const [newData, setnewData] = useState<Orcamento[] | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setdataInicial(data)
        setEmpresas([...new Set(data?.map(orcamento => orcamento.empresas.nome.toString()))].sort())
        setItens([...new Set(data?.map(orcamento => orcamento.itens.nome))].sort())
    }, [])

    return (

        <>
            <Button onClick={onOpen} marginLeft={'15px'} marginBottom={'5px'}>Filtrar</Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Filtros</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel marginTop={'15px'}>Empresa</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: Orcamento[] = data?.filter(fluxo => (fluxo.empresas.nome == (event!.target as HTMLInputElement).value));

                                    setEmpresas([...new Set(newDataa?.map(item => item.empresas.nome.toString()))].sort())
                                    setItens([...new Set(newDataa?.map(item => item.itens.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {empresas?.map((tipo: string) => (
                                <option value={tipo}>{tipo}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Item</FormLabel>
                        <Select placeholder='Selecione...'
                            // eslint-disable-next-line no-restricted-globals
                            onChange={() => {

                                if (data) {

                                    // eslint-disable-next-line no-restricted-globals
                                    var newDataa: Orcamento[] = data?.filter(fluxo => (fluxo.itens.nome == (event!.target as HTMLInputElement).value));

                                    setEmpresas([...new Set(newDataa?.map(item => item.empresas.nome.toString()))].sort())
                                    setItens([...new Set(newDataa?.map(item => item.itens.nome))].sort())

                                    handleFilter(newDataa)

                                }

                            }}>
                            {itens?.map((categoria: string) => (
                                <option value={categoria}>{categoria}</option>
                            ))}
                        </Select>
                        <FormLabel marginTop={'15px'}>Data Mínima</FormLabel>
                        <Input type="date" onChange={() => {

                            if (data) {

                                // eslint-disable-next-line no-restricted-globals
                                const minDate = new Date((event!.target as HTMLInputElement).value)
                                var newDataa: Orcamento[] = data?.filter(fluxo => (new Date(fluxo.createdAt) >= minDate));

                                setEmpresas([...new Set(newDataa?.map(item => item.empresas.nome.toString()))].sort())
                                setItens([...new Set(newDataa?.map(item => item.itens.nome))].sort())

                                handleFilter(newDataa)

                            }

                        }} />
                        <FormLabel marginTop={'15px'}>Data Máxima</FormLabel>
                        <Input type="date" onChange={() => {

                            if (data) {

                                // eslint-disable-next-line no-restricted-globals
                                const maxDate = new Date((event!.target as HTMLInputElement).value)
                                var newDataa: Orcamento[] = data?.filter(fluxo => (new Date(fluxo.createdAt) <= maxDate));

                                setEmpresas([...new Set(newDataa?.map(item => item.empresas.nome.toString()))].sort())
                                setItens([...new Set(newDataa?.map(item => item.itens.nome))].sort())

                                handleFilter(newDataa)

                            }

                        }} />
                    </ModalBody>
                    <ModalFooter marginTop={'20px'}>
                        {/* <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}> */}
                            <Button onClick={() => {
                                setEmpresas([...new Set(dataInicial?.map(item => item.empresas.nome.toString()))].sort())
                                setItens([...new Set(dataInicial?.map(item => item.itens.nome))].sort())
                                handleFilter(dataInicial)
                            }}>Limpar</Button>
                            {/* <Button onClick={() => handleFilter(newData)}>Aplicar</Button> */}
                        {/* </Flex> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )

}


